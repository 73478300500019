import * as tslib_1 from "tslib";
import { Platform } from '@ionic/angular';
import * as signalR from "@microsoft/signalr";
import { nextTick } from "process";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { LangsRepository } from "./langs.repository";
import { LeadClientRepositoryBUT } from "./lead.clients.repository.but";
import { NotificationService } from "./notification.service";
import { WhatsAppChatType } from "../Enums/whatsappChatType.enum";
var WhatsappService = /** @class */ (function () {
    function WhatsappService(langsRepository, leadClientRepository, notificationService, platform) {
        this.langsRepository = langsRepository;
        this.leadClientRepository = leadClientRepository;
        this.notificationService = notificationService;
        this.platform = platform;
        this.isConnected = false;
        this.signalRConnectionSubject = new BehaviorSubject(null);
        this.signalRConnection$ = this.signalRConnectionSubject.asObservable();
        this.receivedMessageSubject = new BehaviorSubject(null);
        this.receivedMessage$ = this.receivedMessageSubject.asObservable();
        this.signalRConnectionCloseRequested = false;
        this.token = "";
        this.chatType = WhatsAppChatType.Client;
    }
    Object.defineProperty(WhatsappService.prototype, "currentLang", {
        get: function () { return this.langsRepository.current; },
        enumerable: true,
        configurable: true
    });
    ;
    WhatsappService.prototype.setSignalRConnection = function (signalRConnection) {
        this.signalRConnectionSubject.next(signalRConnection);
    };
    WhatsappService.prototype.setReceivedMessage = function (receivedMessage) {
        this.receivedMessageSubject.next(receivedMessage);
    };
    WhatsappService.prototype.setToken = function (token) {
        this.token = token;
    };
    WhatsappService.prototype.setWhatsAppChatType = function (chatType) {
        this.chatType = chatType;
    };
    WhatsappService.prototype.startConnection = function (userEmail) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    console.log('startConnection', userEmail);
                    this.signalRConnectionCloseRequested = false;
                    this.signalRConnection.start().then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!this.signalRConnection) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.signalRConnection.invoke('ConnectUser', userEmail)];
                                case 1:
                                    _a.sent();
                                    this.isConnected = true;
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); }, function (err) {
                        console.error('start signalRConnection rejected', err);
                    }).catch(function (err) {
                        console.error('start signalRConnection error', err);
                    });
                }
                catch (err) {
                    console.error('startConnection error', err);
                    setTimeout(function () {
                        _this.startConnection(userEmail);
                    }, 5000);
                }
                return [2 /*return*/];
            });
        });
    };
    ;
    WhatsappService.prototype.subscribe = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var connection;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.platform.ready()];
                    case 1:
                        _a.sent();
                        this.signalRConnectionSubscription = this.signalRConnection$.subscribe(function (con) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(con !== null && !this.signalRConnection)) return [3 /*break*/, 2];
                                        this.signalRConnection = con;
                                        return [4 /*yield*/, this.startConnection(user.email)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        connection = new signalR.HubConnectionBuilder()
                            .withUrl(environment.baseFixUrl + "chatHub", { transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true })
                            .configureLogging(signalR.LogLevel.Information)
                            .withAutomaticReconnect()
                            .build();
                        connection.onclose(function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!!this.signalRConnectionCloseRequested) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.startConnection(user.email)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2:
                                        if (error) {
                                            console.error("error in connection.onclose", error);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this.setSignalRConnection(connection);
                        return [2 /*return*/];
                }
            });
        });
    };
    WhatsappService.prototype.unsubscribe = function (user) {
        var _this = this;
        if (this.signalRConnection) {
            if (this.signalRConnection.state == signalR.HubConnectionState.Connected) {
                this.signalRConnection.invoke('DisconnectUser', user.email)
                    .finally(function () {
                    if (_this.signalRConnection) {
                        _this.signalRConnectionCloseRequested = true;
                        if (_this.isConnected) {
                            _this.signalRConnection.stop().then(function (_) {
                                _this.signalRConnection = null;
                                _this.setSignalRConnection(null);
                                _this.isConnected = false;
                            });
                        }
                    }
                });
            }
            else {
                this.signalRConnection = null;
                this.setSignalRConnection(null);
            }
            if (this.signalRConnectionSubscription) {
                this.signalRConnectionSubscription.unsubscribe();
            }
            this.setReceivedMessage(null);
            this.receivedMessage = null;
            if (this.receivedMessageSubscription) {
                this.receivedMessageSubscription.unsubscribe();
            }
        }
    };
    WhatsappService.prototype.formatWhatsappMessage = function (msg) {
        var _this = this;
        if (!msg.message) {
            return "";
        }
        msg.message = msg.message.replace(/\n/g, "<br />");
        var urlRegex = /\b((https?:\/\/\S+(?:png|jpe?g|gif)\S*)|(https?:\/\/api\-eu\.nexmo\.com\/v3\/media\/\S+))\b/gi;
        var fileRegex = /\b((leadClient\/getMessageFile\/(\w+)\/)(.+\.\w+))\b/i;
        var fileNameRegex = /[^\/]+$/;
        var datePast = new Date();
        var createDate = new Date(msg.createDate);
        if (urlRegex.test(msg.message)) {
            datePast.setDate(datePast.getDate() - 90);
            if (createDate <= datePast) {
                return msg.message.replace(urlRegex, "<b>" + this.currentLang.words["image-deleted"] + "</b>");
            }
            return msg.message
                // replace uploaded images to alias with https (instead of http)
                .replace("http://prod2-clp-fixdigital.s3-website-eu-west-1.amazonaws.com", "https://lpc.fixdigital.co.il")
                .replace(urlRegex, "<a href='$1' target='_blank'><img src='$1' alt='image' /></a>");
        }
        if (fileRegex.test(msg.message)) {
            datePast.setDate(datePast.getDate() - 90);
            if (createDate <= datePast || msg.message.includes('/deleted/')) {
                return msg.message.replace(fileRegex, "<b>" + this.currentLang.words["image-deleted"] + "</b>");
            }
            if (!msg.fileUrl) {
                var url_1 = msg.message.match(fileRegex)[2] + encodeURIComponent(msg.message.match(fileRegex)[4]);
                var getMessageFileMethodPromise = this.chatType === WhatsAppChatType.Client
                    ? this.leadClientRepository.getMessageFile(url_1)
                    : this.leadClientRepository.getMessageFileWithCrmToken(this.token, url_1);
                getMessageFileMethodPromise.then(function (blob) {
                    msg.file = blob;
                    var fileUrl = window.URL.createObjectURL(blob);
                    msg.fileUrl = fileUrl;
                })
                    .catch(function (reason) {
                    console.warn(reason, url_1);
                    msg.fileUrl = null;
                    msg.message = "<b>" + _this.currentLang.words["message-error"] + "</b>";
                });
                msg.fileUrl = '/loading.svg';
            }
            if (msg.messageContentType == "video") {
                return msg.message.replace(fileRegex, "<video controls><source src='" + msg.fileUrl + "'> </video>");
            }
            if (msg.messageContentType == "audio") {
                return msg.message.replace(fileRegex, "<audio controls><source src='" + msg.fileUrl + "'> </audio>");
            }
            if (msg.messageContentType == "file") {
                fileRegex.lastIndex = 0;
                var match = fileRegex.exec(msg.message);
                var matchFilename = fileNameRegex.exec(match[0]);
                var filename = match ? matchFilename[0] : "unknown";
                return msg.message.replace(fileRegex, this.createFileMessageHtml(filename, msg.fileUrl));
            }
            nextTick(function () {
                msg.imgSrc = msg.fileUrl;
            });
            return msg.message.replace(fileRegex, "");
        }
        if (msg.adPreview) {
            return msg.message + " " + msg.adPreview;
        }
        return msg.message;
    };
    WhatsappService.prototype.createFileMessageHtml = function (filename, fileUrl) {
        return "<div class='file'>" +
            "<span class='file-icon'></span>" +
            "<div class='meta'>" +
            ("<div class='title'>" + filename + "</div>") +
            ("<a target=\"_blank\" href='" + fileUrl + "' class=\"download\" download=" + filename + ">Download</a>") +
            "</div>" +
            "</div>";
    };
    return WhatsappService;
}());
export { WhatsappService };
