import * as tslib_1 from "tslib";
import { DataService } from './DataServices/data.service';
import { BehaviorSubject } from 'rxjs';
import { LeadClientsRepository } from './lead.clients.repository';
import { LangsRepository } from './langs.repository';
var LeadClientRepositoryBUT = /** @class */ (function (_super) {
    tslib_1.__extends(LeadClientRepositoryBUT, _super);
    function LeadClientRepositoryBUT(dataService, langsRepository) {
        var _this = _super.call(this, dataService, langsRepository) || this;
        _this.dataService = dataService;
        _this.langsRepository = langsRepository;
        _this.lastReload = 0;
        _this.ready = new BehaviorSubject(false);
        _this.leadClientsReady = new BehaviorSubject(false);
        _this.dataService.token.subscribe(function (token) {
            if (token) {
                _this.ready.next(true);
            }
            else {
                _this.leadCLients = [];
                _this.ready.next(false);
            }
        });
        return _this;
    }
    LeadClientRepositoryBUT.prototype.getLeadClientById = function (id) {
        return this.leadCLients.find(function (x) { return x.id === id; }) || null;
    };
    LeadClientRepositoryBUT.prototype.invalidate = function () {
        this.lastReload = 0;
    };
    LeadClientRepositoryBUT.prototype.loadLeadClients = function (from, q, search, filter, sort, dir, processId) {
        this.setLeadClientsReady(false);
        return this.dataService.but_getLeadClients(from, q, search, filter, sort, dir, processId);
    };
    LeadClientRepositoryBUT.prototype.loadLeadClient = function (id) {
        return this.dataService.but_getLeadClient(id);
    };
    LeadClientRepositoryBUT.prototype.sendUpdateLeadClientFollowup = function (id, followUp) {
        return this.dataService.but_updateLeadClientFollowUp(id, followUp);
    };
    LeadClientRepositoryBUT.prototype.sendUpdateLeadClientStatus = function (leadClient) {
        return this.dataService.but_updateLeadClientStatus(leadClient);
    };
    LeadClientRepositoryBUT.prototype.sendUpdateLeadClient = function (id, leadClient) {
        return this.dataService.but_updateLeadClient(leadClient);
    };
    LeadClientRepositoryBUT.prototype.sendAddLeadClient = function (leadClient) {
        return this.dataService.but_addLeadClient(leadClient);
    };
    LeadClientRepositoryBUT.prototype.sendLeadClientDeal = function (dealId, leadClientId, subject, amount, assigneeId, createdDate) {
        return this.dataService.but_updateLeadClientDeal(dealId, leadClientId, subject, amount, assigneeId, createdDate);
    };
    LeadClientRepositoryBUT.prototype.sendLeadClientUpdateDeal = function (dealId, leadClientId, subject, amount, assigneeId) {
        return this.dataService.but_UpdateDeal(dealId, leadClientId, subject, amount, assigneeId);
    };
    LeadClientRepositoryBUT.prototype.getLeadClientDeal = function (id) {
        return this.dataService.but_getDealById(id);
    };
    LeadClientRepositoryBUT.prototype.getLeadClientMeeting = function (id) {
        return this.dataService.but_LeadClientGetMeeting(id);
    };
    LeadClientRepositoryBUT.prototype.CancelLeadClientMeeting = function (id, locale) {
        return this.dataService.but_LeadClientCancelMeeting(id, locale);
    };
    LeadClientRepositoryBUT.prototype.CancelLeadClientDeal = function (id) {
        return this.dataService.but_LeadClientCancelDeal(id);
    };
    LeadClientRepositoryBUT.prototype.addMeeting = function (id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus) {
        return this.dataService.but_LeadClientAddMeeting(id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus);
    };
    LeadClientRepositoryBUT.prototype.updateMeeting = function (id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus) {
        return this.dataService.but_LeadClientUpdateMeeting(id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus);
    };
    LeadClientRepositoryBUT.prototype.getNotes = function (id) {
        return this.dataService.but_getLeadClientNotes(id);
    };
    LeadClientRepositoryBUT.prototype.addNote = function (leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate) {
        return this.dataService.but_addLeadClientNote(leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate);
    };
    LeadClientRepositoryBUT.prototype.getLeadNotes = function (leadId) {
        return this.dataService.but_getLeadNotes(leadId);
    };
    LeadClientRepositoryBUT.prototype.addLeadNote = function (leadId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate) {
        return this.dataService.but_addLeadNote(leadId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate);
    };
    LeadClientRepositoryBUT.prototype.getLeads = function (leadClientId) {
        return this.dataService.but_getLeadsForLeadClient(leadClientId);
    };
    LeadClientRepositoryBUT.prototype.getLead = function (leadId) {
        return this.dataService.but_getLead(leadId);
    };
    LeadClientRepositoryBUT.prototype.getDeals = function (leadClientId) {
        return this.dataService.but_getDealsForLeadClient(leadClientId);
    };
    LeadClientRepositoryBUT.prototype.getMeetings = function (leadClientId) {
        return this.dataService.but_getMeetingsForLeadClient(leadClientId);
    };
    LeadClientRepositoryBUT.prototype.getAllWhatsappMessagesLeadClient = function (leadClientId, phone) {
        return this.dataService.getAllWhatsappMessagesLeadClient(leadClientId, phone);
    };
    LeadClientRepositoryBUT.prototype.getMessageFile = function (url) {
        return this.dataService.getMessageFile(url);
    };
    LeadClientRepositoryBUT.prototype.getTemplatesForLeadClient = function (leadClientId, phone) {
        return this.dataService.getTemplatesForLeadClient(leadClientId, phone);
    };
    LeadClientRepositoryBUT.prototype.markMessagesAsRead = function (leadClientId) {
        return this.dataService.markMessagesAsRead(leadClientId);
    };
    LeadClientRepositoryBUT.prototype.sendReplyWhatsappMessage = function (leadClientId, message, userIdentityName, files, whatsappPhone) {
        if (files && files.length > 0) {
            return this.dataService.sendReplyWhatsappFile(leadClientId, userIdentityName, files, whatsappPhone);
        }
        return this.dataService.sendReplyWhatsappMessage(leadClientId, message, userIdentityName, whatsappPhone);
    };
    LeadClientRepositoryBUT.prototype.sendTemplateWhatsappMessage = function (leadClientId, templateName, userIdentityName, whatsappPhone) {
        return this.dataService.sendTemplateWhatsappMessage(leadClientId, templateName, userIdentityName, whatsappPhone);
    };
    LeadClientRepositoryBUT.prototype.setLeadClientsReady = function (value) {
        this.leadClientsReady.next(value);
    };
    LeadClientRepositoryBUT.prototype.updateLeadClientAddress = function (leadClientId, address, addressCoords) {
        return this.dataService.updateLeadClientAddress(leadClientId, address, addressCoords);
    };
    LeadClientRepositoryBUT.prototype.getAdPreview = function (channelId, adGroupId, adId) {
        return this.dataService.but_getAdPreview(channelId, adGroupId, adId);
    };
    //#region WithCrmToken
    LeadClientRepositoryBUT.prototype.getAllWhatsappMessagesLeadClientWithCrmToken = function (token, leadClientId, phone) {
        return this.dataService.getAllWhatsappMessagesLeadClientWithCrmToken(token, leadClientId, phone);
    };
    LeadClientRepositoryBUT.prototype.getMessageFileWithCrmToken = function (token, url) {
        return this.dataService.getMessageFileWithCrmToken(token, url);
    };
    LeadClientRepositoryBUT.prototype.getLeadClientWithCrmToken = function (token, id) {
        return this.dataService.getLeadClientWithCrmToken(token, id);
    };
    LeadClientRepositoryBUT.prototype.getTemplatesForLeadClientWithCrmToken = function (token, leadClientId, phone) {
        return this.dataService.getTemplatesForLeadClientWithCrmToken(token, leadClientId, phone);
    };
    LeadClientRepositoryBUT.prototype.markMessagesAsReadWithCrmToken = function (token, leadClientId) {
        return this.dataService.markMessagesAsReadWithCrmToken(token, leadClientId);
    };
    LeadClientRepositoryBUT.prototype.sendReplyWhatsappMessageWithCrmToken = function (token, leadClientId, message, userIdentityName, files, whatsappPhone) {
        if (files && files.length > 0) {
            return this.dataService.sendReplyWhatsappFileMessageWithCrmToken(token, leadClientId, message, userIdentityName, files, whatsappPhone);
        }
        return this.dataService.sendReplyWhatsappMessageWithCrmToken(token, leadClientId, message, userIdentityName, whatsappPhone);
    };
    LeadClientRepositoryBUT.prototype.sendTemplateWhatsappMessagetWithCrmToken = function (token, leadClientId, templateName, userIdentityName, whatsappPhone) {
        return this.dataService.sendTemplateWhatsappMessageWithCrmToken(token, leadClientId, templateName, userIdentityName, whatsappPhone);
    };
    LeadClientRepositoryBUT.prototype.getAdPreviewWithCrmToken = function (token, channelId, adGroupId, adId) {
        return this.dataService.but_getAdPreviewWithCrmToken(token, channelId, adGroupId, adId);
    };
    return LeadClientRepositoryBUT;
}(LeadClientsRepository));
export { LeadClientRepositoryBUT };
